export class SurchargeModel {
  surchargeId: number;
  employeeId: string;
  hotelId: number;
  employeeName: string;
  offenceDescription: string;
  createdDateTime: string;
  amount: number;
  offenceDate: string | any;
  status: string;
  createdBy: string;
  surchargedBy: string;
  surchargeReasonId: number;
  surchargeReason: string;
  surchargeType: string;
  updatedDateTime: string;
  updatedBy: string;
  markAsDone: boolean;
}
