export class EmployeePayModel {
  paymentDetailId: number;
  employeeId: number;
  dateLastPaid: string;
  accountNumber: string;
  sortCode: number | string;
  nameOfAccount: string;
  bankName: string;
  employeeName: string;
  departmentName: string;
  totalWorkDays: number;
  baseSalary: number;
  surchargedAmount: 0;
  totalWorkHours: number;
  dueDate: string;
  amountToPay: number;
  amountPaid: number;
  comment: string;
  markAsSelected: boolean;
  transaction: string;
  beneficiaryCode: string;
  costDebitAccount: string;
}
