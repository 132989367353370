export class CityModel {
  majorCityId: string;
  countryId: string;
  country: string;
  stateId: string;
  state: string;
  createdBy: string;
  createdDateTime: string;
  city: string;
  id: any;
  hotelCity: any;
  cityTag: any;
  hotelId: string;
  lgaId: any;
  stateName: any;
  lgaName: any;
}
